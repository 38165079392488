// client/src/App.tsx

import "./App.css";
import Header from "./components/Header"
import Footer from "./components/Footer"
import HomePage from "./components/HomePage"
import { BrowserRouter, Routes, Route } from "react-router";
import React from "react";
import About from "./components/About";
import Account from "./components/Account";
import ContactMe from "./components/ContactMe";

export default function App() {
  const [currentPage, setCurrentPage] = React.useState("HomePage");



  return (
    <BrowserRouter>
      <Header/>
      <Routes>
        <Route path="/" element={<HomePage/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/account" element={<Account/>} />
        <Route path="/contactme" element={<ContactMe/>} />        
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}


          {/* { (currentPage === "HomePage") ? <HomePage /> : null } */}



          {/* <Stack direction={"column"}>
          <Box>

          <Header setCurrentPage={setCurrentPage} currentPage={currentPage}/>
          </Box>
          <Box>

          <Route path="/" element={<HomePage/>}></Route>

          </Box>
          <Box>
          <Footer setCurrentPage={setCurrentPage}/>

          </Box>
        </Stack> */}