import "./Header.css";
import { IconButton, Stack, Button, Box } from '@mui/material';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { Palette } from "../ColorPalette";
import { useLocation, Link } from "react-router";

export default function Header() {
  const location = useLocation()
  const {hash, pathname, search} = location
  console.log(pathname)
    return (
      // <div className='Header' style={{ backgroundColor: '#132A37' }}>
      <Stack direction={"row"} spacing={"1rem"} padding={"1rem"}>
        <IconButton size="small" sx={{ color: Palette.textGreen, backgroundColor: (pathname === "/account" ? Palette.selectedBlue : Palette.unselectedBlue)}} component={Link} to="/account">
          <AccountBoxIcon fontSize="large"/>
        </IconButton>
        {/* <Button variant="contained" size='large' style={{color: Palette.textGreen, backgroundColor: (currentPage === "HomePage" ? Palette.selectedBlue : Palette.unselectedBlue)}} onClick={() => setCurrentPage("HomePage")}>Sky Notify</Button> */}
        <Button variant="contained" size='large' style={{color: Palette.textGreen, backgroundColor: (pathname === "/" ? Palette.selectedBlue : Palette.unselectedBlue)}} component={Link} to="/">Sky Notify</Button>
  
  
        <Button variant="contained" size='large' style={{color: Palette.textGreen, backgroundColor: (pathname === "/about" ? Palette.selectedBlue : Palette.unselectedBlue)}} component={Link} to="/about">About</Button>  
        <Button variant="contained" size='large' style={{color: Palette.textGreen, backgroundColor: (pathname === "/contactme" ? Palette.selectedBlue : Palette.unselectedBlue)}} component={Link} to="/contactme">Contact Me</Button>
      </Stack>
      // </div>
    )
}


// old text color was #ddbea8