import { Palette } from "../ColorPalette";
import "./Footer.css"
import { Box, Divider, Stack, Typography } from "@mui/material";
import { NavLink } from "react-router";


function Footer({ setCurrentPage, currentPage } : any ) {
    return (
      <Box 
        className='Footer' 
        style={{ backgroundColor: Palette.primary, color: Palette.white }}
        display={"flex"}
        justifyContent={"center"}
        textAlign={"center"}        
        
        // height={"10rem"}
        >
          <Stack width={"50rem"} spacing={"0.8rem"}>
            <Typography color={Palette.textGreen}>
              Copyright © 2025 Trenton Peters
            </Typography>
            
            <Divider variant="middle" sx={{ backgroundColor: Palette.white, opacity: 0.4}} />

            <Typography color={Palette.textGreen}>
              Sites:
            </Typography>
            <NavLink to="/about" style={{ color: Palette.textGreen }}>
              About This Website
            </NavLink>
            <NavLink to="/contactme" style={{ color: Palette.textGreen }}>
              Contact Me
            </NavLink>
            <NavLink to="/account" style={{ color: Palette.textGreen }}>
              My Profile
            </NavLink>
            <NavLink to="/privacy" style={{ color: Palette.textGreen }}>
              Privacy Policy
            </NavLink>
          </Stack>
      </Box>
    )
}
export default Footer;