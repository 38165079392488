

export const Palette : any = {
    primary: "#1b3a4b",
    // primary: "#8ACE00", // brat green
    skyBlue: "#778da9",
    white: "#ffffff",
    offWhite: "#aaaaaa",
    textGreen: "#b4dac3",
    black: "#000000",
    grey: "#2e2e2e",
    selectedBlue: "#132A37",
    unselectedBlue: "#22485E"
}